<template>
  <div class="main-content">
    <h3 class="marginBottom20">订单设置</h3>
    <div style="text-align:right">
      <el-button
        type="primary"
        @click="handleSave"
        style="margin-bottom:20px"
        :loading="loading"
        >{{ disabled ? "编辑" : "保存" }}</el-button
      >
    </div>
    <el-card>
      <template #header>
        <div class="flex align-items justify-content-space-between">
          <h4>订单流程设置</h4>
        </div>
      </template>
      <el-descriptions title="" :column="1">
        <el-descriptions-item
          label="订单支付时间"
          :labelStyle="{ width: '150px' }"
        >
          <div class="flex align-items">
            <el-input-number
              v-model="form.payTime"
              :disabled="disabled"
              placeholder="请输入"
              :precision="0"
              style="width: 300px;"
              :max="999"
              :min="1"
              :controls="false"
            >
              <template slot="append">分钟</template>
            </el-input-number>
            <span style="margin: 0 10px;width: 300px;">*系统默认30分钟</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item
          label="订单结束自动归还时间"
          :labelStyle="{ width: '150px' }"
        >
          <div class="flex align-items">
            <el-input-number
              v-model="form.returnTime"
              :disabled="disabled"
              placeholder="请输入"
              style="width: 300px;"
              :precision="0"
              :max="999"
              :min="0"
              :controls="false"
            >
              <template slot="append">分钟</template>
            </el-input-number>
            <span style="margin: 0 10px;width: 300px;">*系统默认5分钟</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item
          label="归还后可售后时间"
          :labelStyle="{ width: '150px' }"
        >
          <div class="flex align-items">
            <el-input-number
              v-model="form.saleTime"
              :disabled="disabled"
              style="width: 300px;"
              placeholder="请输入"
              :precision="0"
              :max="999"
              :min="0"
              :controls="false"
            >
              <template slot="append">小时</template>
            </el-input-number>
            <span style="margin: 0 10px;width: 300px;"
              >*系统默认5小时，最长可设置999小时</span
            >
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
import { settingView, settingUpdate } from "@/api/order/index";
import log from '../../../router/view/log';
export default {
  name: "index",
  data() {
    return {
      form: {
        id: "",
        payTime: "",
        returnTime: "",
        saleTime: "",
      },
      loading: false,
      disabled: true,
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  mounted() {
    this.getView();
  },
  methods: {
    async getView() {
      try {
        const { data } = await settingView();
        // console.log(data)
        this.form = data;
      } catch (e) {
        // console.log('错误',e)
      }
    },
    handleSave() {
      console.log(this.form);
      if (this.disabled) {
        this.disabled = false;
        return;
      }
      if (!this.form.payTime) {
        this.$message.error("订单支付时间不能为空");
        return;
      }
      if (!(this.form.returnTime >= 0)) {
        this.$message.error("订单结束自动归还时间不能为空");
        return;
      }
      if (!this.form.saleTime && this.form.saleTime !== 0) {
        this.$message.error("归还后可售后时间不能为空");
        return;
      }
      if (this.loading) return;
      this.loading = true;
      settingUpdate(this.form)
        .then((res) => {
          this.loading = false;
          this.$message.success("保存成功");
          this.disabled = true;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/deep/.el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
/deep/.el-descriptions-item .el-descriptions-item__container {
  align-items: center;
}
</style>
